.info-container {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
}


h1 {
  color:white;
}

p {
  color: white;
}
