

.main-container {
  /* padding-top: 20px; */
  /* padding-left: 20px; */
}

.main-content-header {
  color:#cccccc;
}

.main-content {
  margin-left: 100px;
  margin-top: 100px;
}




/* Table */

.table-row {
  /* -webkit-transition: background-color 0.1s;
   -moz-transition:    background-color 0.1s;
   -ms-transition:     background-color 0.1s;
   -o-transition:      background-color 0.1s;
   transition:         background-color 0.1s; */
}

td:first-child,
th:first-child {
  border-radius: 5px 0px 0px 5px;
}

 /* Set border-radius on the top-right and bottom-right of the last table data on the table row */
td:last-child,
th:last-child {
  border-radius: 0 5px 5px 0;
}

.table-row:hover {
  /* background-color: #00AB8E; */
  /* background-color: #181a19; */
  background-color: #323232;
  /* border-left: 2px solid #00AB8E; */
  user-select: none !important;

}

.table-row:hover .row-name{
  /* text-decoration: underline; */

}


.table-body:hover{
  cursor: pointer;

}


.site-dropdown-context-menu {
  color: #777;
  background: #f7f7f7;
}

.drop {
  visibility: hidden;
  font-family: 'Helvetica Neue',sans-serif;
}

.drop-toggle{
  background-color: transparent !important;
  border: none !important;
}

.dropdown-menu {
  background-color: #323232 !important;

}

.drop-button {
  color: white !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
}

/* Remove arrow iin drop down */
.dropdown-toggle::after {
  display: none !important;
}

.table-row:hover .drop {
  visibility: visible;
}


.drop-item {
  color: white !important;
  background-color:transparent !important;
}

.drop-item:hover {
  /* text-decoration: underline; */
  background-color: #505050 !important;
}
