.App {
  font-family: sans-serif;
  text-align: center;
}

.bottom-bar {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #201c21FF;
  z-index: 14;

  /* background-color: red; */
}
