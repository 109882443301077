
.all-custom-button, .all-custom-button:hover, .all-custom-button:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;

  width: 100%;
}

.custom-primary-button {
  background-color: #00AB8E !important;
  border: none !important;

}

.custom-primary-button:hover{
  background-color: #327463 !important;
}

.custom-secondary-button {
  background-color: #363942 !important;
  border: none !important;
}

.custom-secondary-button:hover {
  background-color: #242732 !important;
}


.custom-transparent-button {
  background-color: transparent !important;
  border: none !important;
}
