

.wrapper {
  background-color: #201c21;
  color:white;
  padding-top: 200px;

}

.body {
  background-color: red;

}

.title {
  color: #00AB8E;
  font-family: 'Helvetica Neue',sans-serif;
  font-size: 100px;
  font-weight:lighter;
  user-select: none;
  margin-bottom: 100px;
}


.email {
  background: red;
}

.form-control{
  background-color: transparent !important;
  color:white !important;
  outline: none !important;

  font:white;
  border: 2px solid #00AB8E !important;
  /* //border: none !important; */
}



input:focus, input.form-control:focus {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
