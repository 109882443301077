.mainlogo {
  color: #00AB8E;
}

.navitem {
  color:#cccccc;
  cursor: pointer;

}

.navitem:hover {
  color: #999999;
  cursor: pointer;


}

.navbar {
  background-color: #201c21DD;
  z-index: 10;
  margin-left: 10px;
}
