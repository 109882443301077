
#mynetwork .vis-network:focus{
  outline: none !important;
}

.vis-network{
  outline: none !important;
}

#mynetwork .vis-network:focus{
  outline: none !important;
}

#mynetwork{
  outline: none !important;
}

.canvas{
  outline: none !important;
}

.canvas:focus{
  outline: none !important;
}
/* bf8efa07-d70e-45bb-ba9e-a2bd1584d573 */

.div {
  border: '5px solid red',
}
